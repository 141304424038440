import React from "react"
import { graphql } from "gatsby"
import { Container, Grid, Paper, Hidden, Button } from "@material-ui/core"
import ReactMarkdown from 'react-markdown'

import Layout from "../components/theme/layout"
import SEO from "../components/theme/seo"
import Map from "../components/map/index"
import BlueSection from "../components/website/BlueSection"
import Locations from "../components/locations/index"

import Hero from "../images/contact/contact-hero.jpg"

export default function Contact({data}) {

  var backgroundImage = {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${Hero})`
  };

  let strapi = data.allStrapiContact.edges[0].node

  return (
  <Layout>
    <SEO title="Contact" />
    <div className="py-40 contact-header" style={ backgroundImage }>
      <Container>
        <Grid container className="flex justify-center text-center">
          <Grid item md={8} className="white-text">
            <h3>{strapi.contact.heading_title}</h3>
            <p>{strapi.contact.heading_description}</p>
            <a href={`mailto:${strapi.email}`} className="no-underline"><Button>{strapi.contact.button}</Button></a>
          </Grid>
        </Grid>
      </Container>
    </div>
    <div className="contact-container">
    <Container>
        <Grid container>
          <Grid item sm={12} className="contact-info">
            <Paper elevation={3} className="flex m-3">
              <Grid item md={7} sm={12} className="contact-info">
                <ul className="list-none p-20 md:p-40">
                  <li>
                    <p className="orange-text m-0">Head Office</p>
                    <p>{strapi.head_office}</p>
                  </li>
                  <li>
                    <p className="orange-text m-0">Phone</p>
                    <p>
                      <ReactMarkdown>{strapi.local_phone}</ReactMarkdown>
                      <ReactMarkdown>{strapi.tollfree_phone}</ReactMarkdown>
                    </p>
                  </li>
                  <li>
                    <p className="orange-text m-0">Fax</p>
                    <ReactMarkdown>{strapi.fax_local}</ReactMarkdown>
                    <ReactMarkdown>{strapi.fax_tollfree}</ReactMarkdown>                    
                  </li>
                  <li>
                    <p className="orange-text m-0">Email</p>
                    <p><a href={`mailto:${strapi.email}`}>{strapi.email}</a></p>
                  </li>
                  <li>
                    <p className="orange-text m-0">Office Hours</p>
                    <p>{strapi.hours}</p>
                  </li>
                </ul>
              </Grid>
              <Grid item md={5} sm={12} className="contact-map">
                <Hidden smDown>
                  <Map />
                </Hidden>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
    <div className="blue-section white-text mt-40">
      <BlueSection 
        title="Serving you better."
        smalltitle="Our Covid-19 response"
        description="
        Providing home purchase financing right across Canada, the entire team at Marathon has adapted well to working remotely as a result of COVID. 
        We are pleased that we are able to deliver the required service levels at this time.
        "
        order="right"
        component={<Locations />}
      />
      <Hidden smDown>
        <div className="blue-section calc-offset">
          &nbsp;
        </div>
      </Hidden>
    </div>
  </Layout>
  )  
}




export const query = graphql`
query MyQuery {
  allStrapiContact {
    edges {
      node {
        contact {
          id
          heading_title
          heading_description
          button
        }
        email
        fax_local
        fax_tollfree
        head_office
        hours
        local_phone
        tollfree_phone
      }
    }
  }
}
`